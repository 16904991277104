import React, {useEffect, useState} from 'react';

import ReactMarkdown from 'react-markdown';

import Card from '../../components/card/Card';
import {Language} from '../../constants/language.constants';
import {downloadPageData, PageType} from '../../download/constants';
import {DownloadBadges} from './DownloadBadges';
import './DownloadCard.scss';
import {Platform} from '../../constants/app.constants';

export function DownloadCard({
  type,
  language,
  platforms,
}: {
  type: PageType;
  language: Language;
  platforms?: Platform[];
}): JSX.Element {
  const [data, setData] = useState(downloadPageData[type]);

  useEffect(() => {
    setData(downloadPageData[type]);
  }, [type]);

  return (
    <Card className={'Download-Page-Card'}>
      <ReactMarkdown>{data.text[language]}</ReactMarkdown>

      <DownloadBadges type={type} language={language} platforms={platforms} />
    </Card>
  );
}
