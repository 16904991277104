/* eslint-disable @typescript-eslint/no-explicit-any */
import {apiURLs} from '../constants/api-url.constants';

type APIOptions = {
  environment?: string;
};

type ErrorHandlingOptions = {
  throwErrorOnNoSuccess?: boolean;
  errorMessageMap?: ErrorMessageMap;
};

type ErrorMessageMap = {
  [code: string]: string;
};

const defaultErrorHandlingOptions: ErrorHandlingOptions = {
  throwErrorOnNoSuccess: true,
};

type RequestOptions = {
  path: string;
  options?: RequestInit;
  errorHandling?: ErrorHandlingOptions;
  body?: any;
  returnJson?: boolean;
  query?: any;
};

export class API {
  private static instances: {[stringifiedOptions: string]: API} = {};
  private host: string;

  constructor(options?: APIOptions) {
    if (options === undefined) {
      const urlParams = new URLSearchParams(window.location.search);
      const environment = urlParams.get('environment') ?? undefined;
      options = {environment};
    }

    const environment = options.environment ?? 'production';
    if (!(environment in apiURLs)) {
      throw new Error(`Unknown environment ${environment}`);
    }

    console.log('Initializing API with options', options);
    this.host = apiURLs[environment];
  }

  public static get instance() {
    return API.getInstance();
  }

  public static getInstance(options?: APIOptions | undefined): API {
    const instanceString = JSON.stringify(options);
    if (!this.instances[instanceString]) {
      this.instances[instanceString] = new API(options);
    }

    return this.instances[instanceString];
  }

  public async request({
    path,
    body = undefined,
    options = {},
    errorHandling = {},
    returnJson = true,
  }: RequestOptions): Promise<Response | any> {
    errorHandling = {...defaultErrorHandlingOptions, ...errorHandling};

    const result = await fetch(this.host + path, {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      ...options,
      body: body ? JSON.stringify(body) : undefined,
    });

    if (
      errorHandling.throwErrorOnNoSuccess &&
      ![200, 204].includes(result.status)
    ) {
      const body = await result.json();
      let message = result.statusText;

      if ('error' in body && typeof body?.error === 'object') {
        const error = body.error;
        const messageMap: ErrorMessageMap = errorHandling.errorMessageMap ?? {};
        message =
          error.code in messageMap
            ? messageMap[error.code]
            : error.message ?? 'Unknown error';
      }
      throw new Error(message);
    }

    let response: Response | Promise<any> = result;
    if (result.status === 200 && returnJson) {
      response = result.json();
    }

    return response;
  }

  public async get({
    path,
    query,
    options = {},
    errorHandling = {},
  }: RequestOptions): Promise<any> {
    if (query) {
      path += `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(query).map(x => [x[0], JSON.stringify(x[1])]),
        ),
      ).toString()}`;
    }

    return this.request({
      path,
      query,
      options: {method: 'GET', ...options},
      errorHandling,
    });
  }

  public async post({
    path,
    body = {},
    options = {},
    errorHandling = {},
  }: RequestOptions): Promise<any> {
    return this.request({
      path,
      body,
      options: {method: 'POST', ...options},
      errorHandling,
    });
  }

  public async requestResetPassword(email: string): Promise<any> {
    return await this.post({
      path: '/users/reset-password/request',
      body: {email},
    });
  }

  public async resetPassword(hash: string, password: string): Promise<any> {
    return await this.post({
      path: '/users/reset-password',
      body: {hash, password},
      errorHandling: {
        errorMessageMap: {
          VERIFY_TOKEN_FAILED: 'Reset password link has expired',
          NOT_FOUND: 'Reset password link has expired',
        },
      },
    });
  }

  public async unsubscribe(token: string, mailCategory?: string): Promise<any> {
    return await this.post({
      path: '/mail-status/unsubscribe',
      body: {token},
      errorHandling: {
        errorMessageMap: {
          VERIFY_TOKEN_FAILED: 'Unsubscribe link has expired',
          NOT_FOUND: 'Unsubscribe link has expired',
          ER_DUP_ENTRY: `You've already been unsubscribed${
            mailCategory ? ` from: ${mailCategory}` : '!'
          }`,
        },
      },
    });
  }

  async getUnsubscribeInfo(token: string): Promise<{
    email: string;
    mailCategory: {id: number; externalName: string; description: string};
  }> {
    return this.post({
      path: '/mail-status/unsubscribe/info',
      body: {token},
      errorHandling: {
        errorMessageMap: {
          VERIFY_TOKEN_FAILED:
            'Unsubscribe link has expired: though we try to keep the links valid for as long as possible, in the case of security measures, your link may become invalid. Please use a more recent unsubscribe link.',
        },
      },
    });
  }

  public async getLegalDocumentVersion(
    legalDocumentId: number | string,
    versionId?: number,
  ): Promise<any> {
    const findBySlug = isNaN(Number(legalDocumentId));
    const endpoint = `/legal-documents/${
      findBySlug ? 'by-slug/' : ''
    }${legalDocumentId}`;

    return this.get({
      errorHandling: {throwErrorOnNoSuccess: true},
      path: endpoint,
      query: {
        filter: {
          include: [
            {
              relation: 'legalDocumentVersions',
              scope: {
                where: versionId ? {id: versionId} : undefined,
                include: [
                  {
                    relation: 'text',
                    scope: {include: [{relation: 'translations'}]},
                  },
                ],
              },
            },
            {relation: 'title', scope: {include: [{relation: 'translations'}]}},
          ],
        },
      },
    });
  }
}
