import React, {useState} from 'react';

import {Route, Routes} from 'react-router-dom';

import {Alert} from '@mui/lab';
import {Button, FormControl, TextField, Typography} from '@mui/material';

import {API} from '../classes/API';
import styled from '@emotion/styled';

const SlipButton = styled(Button)({
  background:
    'transparent linear-gradient(45deg, #FF5975 60%, #FFFF40 130%) 0% 0% no-repeat padding-box',
  color: 'white',
  fontWeight: 900,
  '&.Mui-disabled': {
    background: 'linear-gradient(45deg, #E0E0E0 60%, #F0F0F0 130%)',
    color: 'rgba(0,0,0, 0.38)',
  },
});

export function ResetPassword(): JSX.Element {
  return (
    <section className="App-section-container-flex contained">
      <Routes>
        <Route path="/request" element={<RequestResetPassword />} />
        <Route path="/" element={<ResetPasswordForm />} />
      </Routes>
    </section>
  );
}

function RequestResetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);
  const parts = email.split('@');
  const canSubmit =
    parts.length === 2 &&
    parts.filter(x => x.length > 0).length === 2 &&
    parts[1].split('.').length >= 2 &&
    parts[1].split('.').filter(x => x.length > 0).length >= 2;

  const urlParams = new URLSearchParams(window.location.search);
  const environment = urlParams.get('environment') ?? undefined;

  const api = API.getInstance({environment});
  const resetPassword = async () => {
    try {
      await api.requestResetPassword(email);
      setSuccess(true);
    } catch (e) {
      console.error('Reset password error:', e);
      setError(e as Error);
    }
  };

  return (
    <>
      <h2 className="App-section-h2" style={{marginBottom: '1em'}}>
        Reset your password
      </h2>
      {error ? (
        <Alert severity="error">{error.message}</Alert>
      ) : success ? (
        <Alert severity="success">
          An email has been sent with a link to reset your password.
        </Alert>
      ) : (
        <FormControl>
          <TextField
            id="standard-password-input"
            label="Email address"
            type="text"
            autoComplete="current-password"
            variant="standard"
            required
            style={{marginTop: '1em'}}
            value={email}
            fullWidth
            onChange={e => setEmail(e.target.value)}
          />
          <SlipButton
            type="submit"
            variant="contained"
            style={{marginTop: '1em'}}
            disabled={!canSubmit}
            onClick={resetPassword}>
            Submit
          </SlipButton>
        </FormControl>
      )}
    </>
  );
}

function ResetPasswordForm() {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);
  const match = password1 === password2;
  const valid = password1.length >= 8;
  const canSubmit = match && valid;

  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get('hash');
  const environment = urlParams.get('environment') ?? undefined;

  if (!hash && !error) {
    setError(new Error('Invalid password reset link'));
  }

  const api = API.getInstance({environment});
  const resetPassword = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await api.resetPassword(hash!, password1);
      setSuccess(true);
    } catch (e) {
      console.error('Reset password error:', e);
      setError(e as Error);
    }
  };

  return (
    <>
      <h2 className="App-section-h2" style={{marginBottom: '1em'}}>
        Reset your password
      </h2>
      {error ? (
        <Alert severity="error">{error.message}</Alert>
      ) : success ? (
        <Alert severity="success">
          All set! You can now log in on your phone.
        </Alert>
      ) : (
        <FormControl>
          <TextField
            id="standard-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            required
            style={{marginTop: '1em'}}
            value={password1}
            onChange={e => setPassword1(e.target.value)}
          />
          <TextField
            id="standard-password-input-repeat"
            label="Repeat Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            required
            style={{marginTop: '1em'}}
            value={password2}
            onChange={e => setPassword2(e.target.value)}
          />
          <SlipButton
            type="submit"
            variant="contained"
            color="primary"
            style={{marginTop: '1em'}}
            disabled={!canSubmit}
            onClick={resetPassword}>
            Submit
          </SlipButton>

          <Typography
            variant="subtitle1"
            style={{marginTop: '2rem', marginBottom: '1rem'}}>
            Your password should be at least 8 characters long.
          </Typography>
          <Typography variant="body2" style={{fontStyle: 'italic'}}>
            Tip: try coming up with a sentence related to Slip. Sentences are
            long enough to be secure and easier to remember, and unique if you
            relate it to the platform you're on.
          </Typography>
          <Typography paragraph variant="body2" style={{fontStyle: 'italic'}}>
            For example: "I wish I used a password manager, but at least this
            sentence won't SLIP from my mind". This would be an extremely secure
            password.
          </Typography>
        </FormControl>
      )}
    </>
  );
}
