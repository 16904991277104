import React from 'react';

import {Link, useLocation} from 'react-router-dom';

import {ThemeProvider, createTheme} from '@mui/material';

import './App.scss';
import logo from './assets/images/logo.svg';
import Footer from './components/footer/Footer';
import useScrollToTop from './hooks/useScrollToTop';
import SmartBanner from './components/smartbanner/SmartBanner';

const theme = createTheme();

function App(props: {children?: React.ReactNode}): JSX.Element {
  useScrollToTop();
  const {pathname} = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Link className="App-logo" to="/">
          <img src={logo} alt="slip-logo" />
        </Link>
        <SmartBanner
          title={'Slip.'}
          author="Slip B.V."
          position="bottom"
          storeText={{ios: 'On the App Store', android: 'In Google Play Store'}}
        />
        <main
          // avoid a horizontal overflow in LandingPage.tsx
          style={{...(pathname === '/' && {overflow: 'hidden'})}}>
          {props.children}
        </main>
        <Footer></Footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
