import {useCallback, useEffect, useRef} from 'react';
import {DownloadCard} from '../../components/download/DownloadCard';
import LangSelection from '../../components/langSelection/LangSelection';
import {Language, supportedLang} from '../../constants/language.constants';
import useLanguage from '../../hooks/useLanguage';
import './LaunchPage.scss';
import {PageType} from '../../download/constants';
import {isAndroid, isIOS} from 'react-device-detect';
import {Platform, PlatformMap} from '../../constants/app.constants';
import useQuery from '../../hooks/useQuery';

export function LaunchPage(): JSX.Element {
  const [lang, setLang] = useLanguage();
  const ref = useRef<HTMLAnchorElement>(null);
  const query = useQuery();

  const onClick = useCallback(() => {
    const totp = query.get('totp');
    const goto = query.get('goto') ?? (totp ? 'magiclinkLogIn' : undefined); // default to magiclinkLogIn if totp is present for backward compatibility
    if (!goto || ['magiclinkLogIn', 'password'].includes(goto)) {
      window.location.href = goto
        ? `slipapp://goto/${goto}/${totp ? `?totp=${totp}` : ''}`
        : 'slipapp://';
      window.close();
    }
  }, [query]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.click();
      }
    }, 10);
  });

  const platforms: Platform[] | undefined = isAndroid
    ? [PlatformMap.Android]
    : isIOS
    ? [PlatformMap.iOS]
    : undefined;

  return (
    <section className="App-section-container Download-Page">
      <LangSelection
        languages={supportedLang}
        selected={lang}
        onSelect={selected => {
          supportedLang.includes(selected as Language) &&
            setLang(selected as Language);
        }}
      />
      <a ref={ref} id="launch" onClick={onClick}>
        <DownloadCard
          language={lang}
          type={PageType.LAUNCH}
          platforms={platforms}
        />
      </a>
    </section>
  );
}
