import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Alert} from '@mui/lab';
import {Button, FormControl, Typography} from '@mui/material';

import {API} from '../classes/API';
import {themeLight} from '../theme/Theme';
import styled from '@emotion/styled';

const SlipButton = styled(Button)({
  background: themeLight.colors.primary3,
  borderRadius: 10,
  color: 'white',
});

type MailCategory = {
  id: number;
  externalName: string;
  description: string;
};

export function Unsubscribe(): JSX.Element {
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);

  const [mailCategory, setMailCategory] = useState<MailCategory | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const environment = urlParams.get('environment') ?? undefined;

  if (!token && !error) {
    setError(new Error('Invalid unsubscribe link'));
  }

  const api = useMemo(() => API.getInstance({environment}), [environment]);
  const unsubscribe = useCallback(async () => {
    try {
      await api.unsubscribe(token!, mailCategory?.externalName);
      setSuccess(true);
    } catch (e) {
      console.error('Unsubscribe error:', e);
      setError(e as Error);
    }
  }, [api, token, mailCategory?.externalName]);

  useEffect(() => {
    if (token) {
      api
        .getUnsubscribeInfo(token)
        .then(info => setMailCategory(info.mailCategory))
        .catch(e => setError(e));
    }
  }, [api, token]);

  return (
    <section className="App-section-container-flex contained">
      <h2 className="App-section-h2" style={{marginBottom: '1em'}}>
        Unsubscribe from Slip mails
        {mailCategory ? `: ${mailCategory.externalName}` : ''}
      </h2>
      {error ? (
        <Alert severity="error">{error.message}</Alert>
      ) : success ? (
        <Alert severity="success">
          All set! You've unsubscribed from future Slip emails
          {mailCategory ? `: ${mailCategory.externalName}` : ''}.
        </Alert>
      ) : (
        <FormControl>
          <SlipButton
            type="submit"
            variant="contained"
            color="primary"
            style={{marginTop: '1em'}}
            onClick={unsubscribe}>
            Unsubscribe from {mailCategory?.externalName ?? 'Slip mails'}
          </SlipButton>

          <Typography variant="subtitle1" style={{marginTop: '2em'}}>
            We're sad to see you go, and we hate to see you leave - we'll
            welcome you back with open arms if you ever change your mind!
          </Typography>
        </FormControl>
      )}
    </section>
  );
}
